import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { TestimonialSection } from 'components/sections'
import { SignupButton, Row, Typography,  Container, Section, SectionTitle, FeatureGrid, Feature, FeatureRows } from "components/ui"
import Image from "components/image"
import { ValuePropositions } from 'components/sections'

const ContentPage = () => (
  <Layout>
    <SEO refKey="teams-and-projects" />

    <Section color="alt">
      <Container>
        <Row>
          <div className="col-md-12 text-center">
            <SectionTitle
              tag="h1"
              align="center"
              title={(<><strong>Give access to your backups</strong><br/> in total control.</>)}
              subTitle={(<>Organize Backups and grant access to Team Members.</>)}
            />
            <SignupButton className="mt-12" text="Create your first backup" urlAtrr={{ sb_source: "website", sb_term: "increment" }} />
          </div>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <div className="colspan-12">
              <div className="grid md:grid-cols-3 gap-16 text-left">
                  <div>
                      <i className="fad fa-people text-primary text-4xl" />
                      <h3 className="text-xl font-bold mt-4 mb-1">Invite Team Members</h3>
                      <p className="text-base">No need to share password and accounts, invite as many team members as you need.</p>
                  </div>
                  <div>
                      <i className="fad fa-lock text-primary text-4xl" />
                      <h3 className="text-xl font-bold mt-4 mb-1">Manage Accesses</h3>
                      <p className="text-base">Define who can view or create backups from within your Team and create Projects to limit which Backups they can access.</p>
                  </div>
                  <div>
                      <i className="fad fa-hand text-primary text-4xl" />
                      <h3 className="text-xl font-bold mt-4 mb-1">Share access to your customers</h3>
                      <p className="text-base">Leverage the power of Projects to give your customers access to their backups, and their backup only.</p>
                  </div>
                  <div>
                      <i className="fad fa-circle text-primary text-4xl" />
                      <h3 className="text-xl font-bold mt-4 mb-1">One account for all your Backups</h3>
                      <p className="text-base">Working with different companies? No worries, one account can join as many Team as you want.</p>
                  </div>
              </div>
          </div>
        </Row>
      </Container>
    </Section>

    <TestimonialSection/>

  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "teams-and-projects",
    title: "Teams and Projects",
    description: "Organize Backups and grant access to Team Members.",
    author: "simplebackups.com"
  }
}

export default ContentPage
